// Garments.js
import React from 'react';
import './garments.css';

const Garments = () => {
  return (
    <div className="garments">
      <div className="text">
        our inventory is in the process of being digitalized
      </div>
      {/* Add more elements here if needed */}
    </div>
  );
};

export default Garments;
